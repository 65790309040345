import { possibleTypes } from "@/b";
import {
  ApolloClient,
  ApolloLink,
  ApolloQueryResult,
  InMemoryCache,
  OperationVariables,
  QueryResult,
  SuspenseQueryHookOptions,
  from,
  useApolloClient,
  useSuspenseQuery,
} from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { ResultOf, TypedDocumentNode } from "@graphql-typed-document-node/core";
import { GetServerSidePropsContext } from "next";
import { useCallback, useRef } from "react";

export function createApolloClient(
  cookies?: string,
  onResponseHeaders?: (headers: Headers) => void
) {
  let uri = "/graphql";
  const links: ApolloLink[] = [];
  if (typeof window === "undefined") {
    uri = process.env.GRAPHQL_URL!;
    links.push(
      new ApolloLink((operation, forward) => {
        return forward(operation).map((response) => {
          const context = operation.getContext();
          const {
            response: { headers },
          } = context;
          if (headers) {
            onResponseHeaders?.(headers);
          }
          return response;
        });
      })
    );
    links.push(
      new BatchHttpLink({
        uri,
        headers: cookies
          ? {
              cookie: cookies,
            }
          : undefined,
      })
    );
  } else {
    links.push(
      new BatchHttpLink({
        uri,
        credentials: "include",
      })
    );
  }

  return new ApolloClient({
    link: from(links),
    cache: new InMemoryCache({
      possibleTypes,
      typePolicies: {
        EmbeddedProductOption: {
          keyFields: false,
          fields: {
            ingredientModifications: {
              merge: (existing, incoming) => incoming,
            },
            selections: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Employee: {
          fields: {
            roles: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        ProductSelection: {
          keyFields: false,
        },
        FieldError: {
          fields: {
            path: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Admin: {
          keyFields: () => "ADMIN",
          fields: {
            orders: {
              merge: (existing, incoming, options) => {
                if (options.args?.after === null) {
                  return incoming;
                }
                return [...existing, ...incoming];
              },
            },
            keyPerformanceIndicators: {
              merge: (existing, incoming) => incoming,
            },
            recentGiftCards: {
              merge: (existing, incoming) => incoming,
            },
            selectedDates: {
              merge: (existing, incoming) => incoming,
            },
            cookDay: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Cart: {
          keyFields: () => "Cart",
          fields: {
            deliveryDays: {
              merge: (existing, incoming) => incoming,
            },
            items: {
              merge: (existing, incoming) => incoming,
            },
            subtotal: {
              merge: (existing, incoming) => incoming,
            },
            messages: {
              merge: (existing, incoming) => incoming,
            },
            issuesPreventingCheckout: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Address: {
          keyFields: ["hash"],
        },
        Account: {
          fields: {
            addresses: {
              merge: (existing, incoming) => incoming,
            },
            subscriptions: {
              merge: (existing, incoming) => incoming,
            },
            orders: {
              merge: (existing, incoming) => incoming,
            },
            upcomingOrders: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        CartItemProduct: {
          fields: {
            discounts: {
              merge: (existing, incoming) => incoming,
            },
            selectionPaths: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        CartDeliveryDay: {
          fields: {
            reasons: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        CheckoutMetadata: {
          fields: {
            availablePickupLocations: {
              merge: (existing, incoming) => incoming,
            },
            deliveryZipCodes: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Column: {
          fields: {
            data: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        ColumnFilter: {
          fields: {
            selected: {
              merge: (existing, incoming) => incoming,
            },
            values: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        SelectionPath: {
          fields: {
            elements: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        CookDay: {
          keyFields: false,
          fields: {
            recipes: {
              merge: (existing, incoming) => incoming,
            },
            rawIngredients: {
              merge: (existing, incoming) => incoming,
            },
            orders: {
              merge: (existing, incoming) => incoming,
            },
            products: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        CookDayRecipe: {
          fields: {
            lastBatch: {
              merge: (existing, incoming) => incoming,
            },
            totals: {
              merge: (existing, incoming) => incoming,
            },
            madeInto: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        CookDayProduct: {
          fields: {
            recipes: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        CookDayRecipeProduct: {
          fields: {
            selectionSummaries: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Ingredient: {
          fields: {
            subIngredients: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Invoice: {
          fields: {
            discountTotals: {
              merge: (existing, incoming) => incoming,
            },
            discounts: {
              merge: (existing, incoming) => incoming,
            },
            items: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        InvoiceItem: {
          fields: {
            discounts: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Metrics: {
          fields: {
            affiliateMetrics: {
              merge: (existing, incoming) => incoming,
            },
            productMetrics: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Order: {
          fields: {
            items: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        OrderItemProduct: {
          fields: {
            ingredients: {
              merge: (existing, incoming) => incoming,
            },
            selectionSummaries: {
              merge: (existing, incoming) => incoming,
            },
            selections: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Product: {
          fields: {
            images: {
              merge: (existing, incoming) => incoming,
            },
            options: {
              merge: (existing, incoming) => incoming,
            },
            similar: {
              merge: (existing, incoming) => incoming,
            },
            tags: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        ProductOption: {
          fields: {
            ingredientModifications: {
              merge: (existing, incoming) => incoming,
            },
            selections: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Recipe: {
          fields: {
            ingredients: {
              merge: (existing, incoming) => incoming,
            },
            steps: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        SharedOption: {
          fields: {
            ingredientModifications: {
              merge: (existing, incoming) => incoming,
            },
            selections: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        SkipIssues: {
          fields: {
            issues: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Table: {
          fields: {
            columns: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Tag: {
          fields: {
            products: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        ValidationError: {
          fields: {
            errors: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
        Query: {
          fields: {
            allProducts: {
              merge: (existing, incoming) => incoming,
            },
            allSharedOptions: {
              merge: (existing, incoming) => incoming,
            },
            allTags: {
              merge: (existing, incoming) => incoming,
            },
            pickupLocations: {
              merge: (existing, incoming) => incoming,
            },
          },
        },
      },
    }),
    connectToDevTools: true,
    ssrMode: typeof window === "undefined",
    ssrForceFetchDelay: 100,
  });
}

const APOLLO_STATE_QUERY_PROP_NAME = "__APOLLO_STATE_QUERY__";

type ApolloQueryProps<Query extends TypedDocumentNode<any, any>> = {
  [APOLLO_STATE_QUERY_PROP_NAME]: {
    result: ApolloQueryResult<ResultOf<Query>>["data"];
    query: Query;
    variables: Query extends TypedDocumentNode<any, infer V> ? V : never;
  };
};

export async function getApolloSSRProps<
  Query extends TypedDocumentNode<any, any>,
>(
  query: Query,
  variables: Query extends TypedDocumentNode<any, infer V> ? V : never,
  { req, res }: GetServerSidePropsContext
): Promise<ApolloQueryProps<Query>> {
  let setCookie: string | null = null;
  const client = createApolloClient(req.headers.cookie, (headers) => {
    setCookie = headers.get("set-cookie");
    const requestId = headers.get("x-request-id");
    if (requestId) {
      res.setHeader("x-request-id", requestId);
    }
  });

  const result = await client.query({
    query,
    variables,
  });

  if (setCookie) {
    res.setHeader("set-cookie", setCookie);
  }

  return {
    [APOLLO_STATE_QUERY_PROP_NAME]: {
      result: result.data,
      query,
      variables,
    },
  };
}

export function useHydratedQuery<Props extends ApolloQueryProps<any>>(
  props: Props,
  options?: Props[typeof APOLLO_STATE_QUERY_PROP_NAME]["query"] extends TypedDocumentNode<
    infer Data,
    infer Vars extends OperationVariables
  >
    ? SuspenseQueryHookOptions<Data, Vars>
    : never
): Props[typeof APOLLO_STATE_QUERY_PROP_NAME] extends {
  query: TypedDocumentNode<infer Data, infer Vars extends OperationVariables>;
}
  ? QueryResult<Data, Vars> & {
      data: Data;
    }
  : never {
  const isHydrated = useRef(false);
  const client = useApolloClient();
  const {
    query,
    variables: ssrVariables,
    result: ssrData,
  } = props[APOLLO_STATE_QUERY_PROP_NAME];

  const hydrateCache = useCallback(() => {
    if (isHydrated.current) {
      return;
    }
    client.cache.writeQuery({
      query,
      data: ssrData,
      variables: ssrVariables,
      overwrite: true,
    });
    isHydrated.current = true;
  }, [client, query, ssrData, ssrVariables]);

  hydrateCache();

  const computedOptions = Object.assign(options ?? {}, {
    variables: options?.variables ?? ssrVariables,
  } as Partial<SuspenseQueryHookOptions<any, any>>) as SuspenseQueryHookOptions<
    any,
    any
  >;

  return useSuspenseQuery(query, computedOptions) as any;
}
