import { useDeferredLoad } from "@/util/hook";
import Script from "next/script";

export const PodiumScript = () => {
  return (
    <Script
      defer
      src="https://connect.podium.com/widget.js#ORG_TOKEN=54e1f4ab-1320-45c7-bd53-1a4580d4146c"
      id="podium-widget"
      data-organization-api-token="54e1f4ab-1320-45c7-bd53-1a4580d4146c"
      strategy="lazyOnload"
    />
  );
};
