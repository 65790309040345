/* eslint-disable */
import type { Builder } from "./builder";
import { builder } from "@gqlb/core";
export const b = builder as any as Builder;
export const possibleTypes = {
  CartIssue: ["CartGeneralIssue", "CartItemIssue"],
  CartItem: ["CartItemProduct", "CartItemSubscription"],
  CartItemMutation: ["CartItemProductMutation", "CartItemSubscriptionMutation"],
  CartSubtotal: ["Currency", "SubscriptionCurrency"],
  CheckoutResult: ["MutationError", "Order", "PaymentIntent"],
  CookDayRecipeFor: ["CookDayRecipeProduct", "Ingredient"],
  InvoiceLineItem: ["InvoiceItem", "InvoiceSubscriptionItem"],
  OptionSubSelections: ["EmbeddedProductOption", "ProductSelection"],
  OrderDelivery: ["OrderDeliveryHome", "OrderDeliveryPickup"],
  OrderItem: ["OrderItemExtra", "OrderItemProduct", "OrderItemSubscription"],
  OrderPayment: ["StripeInvoice", "StripePaymentIntent"],
  ProductOption: ["EmbeddedProductOption", "SharedOption"],
  SelectionPathElement: [
    "EmbeddedProductOption",
    "ProductSelection",
    "SharedOption",
  ],
  SkipResult: ["Order", "SkipIssues"],
  Unit: ["VolumeUnit", "WeightUnit"],
};
export type { types } from "./types";
